<template>
  <div class="chart">
    <div id="housechart"></div>
  </div>
</template>
<script>
export default {
  props: {
    houseRatioData: {},
  },
  data() {
    return {
      houseChart:{}
    };
  },
  watch:{
    houseRatioData:{
      handler: function(){
          this.renderEcharts();
      },
      deep: true
    }
  },
  mounted(){
    setTimeout(() => {
      this.renderEcharts();
    }, 500);
    
  },
  
  methods: {
    renderEcharts(){
      this.houseChart = this.$echarts.init(document.getElementById('housechart'));
      let option;
      option = {
          tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          legend: {
              orient: 'vertical',
              icon: "circle",
              right: 20,
              bottom:20,
              data: ['已售出','未售出', '不可售'],
            
          },
          series: [
              {
                  name: '百分比',
                  type: 'pie',
                  radius: '90%',
                  avoidLabelOverlap: false,
                  center: ['135px', '50%'],
                  label: {
                    normal:{
                        show:true,
                        position:'inner', //标签的位置
                        textStyle : {
                          color:'#fff',
                          fontWeight : 300 ,
                          fontSize : 14    //文字的字体大小
                        },
                        formatter:function (params) {
                          if(params.value==0){  //为0时不显示
                              return '' 
                          }else{
                              return params.value
                          }
                        }
                    }
                  },
               
                  labelLine: {
                      show: false
                  },
                  data: [
                      {value: this.houseRatioData.soldCount, name: '已售出'},
                      {value: this.houseRatioData.unsoldCount, name: '未售出'},
                      {value: this.houseRatioData.notAvailableSaleCount, name: '不可售'},
                  ]
              }
          ]
      };


      this.houseChart.setOption(option,true);
    },

    
  },
};
</script>
<style lang="less" scoped>
.chart{
  width: 381px;
  height: 400px;
  margin-top: 24px;
 
}
#housechart{
  width: 381px;
  height: 230px;
}
</style>