<template>
  <!--项目概览-->
  <div class="contain">
    <div class="main">
      <div class="tag">
        网签数据 / <span style="color: #646566">区域概览</span>
      </div>
      <div class="back" @click="back">返回上级</div>
      <!-- 顶部 -->
      <div class="item_box">
        <div class="item_title">区域概览基本信息</div>
        <div class="map">
          <i class="el-icon-location-outline" style="margin-right:8px"></i
          >{{ this.province + '-' + this.city }}{{this.county.length>0?'-'+this.county:''}}
        </div>
        <div class="tb_left">
          <div class="pro_card">
            <p class="card_title">可清算项目数</p>
            <p class="card_txt">{{ basicInfo.generalLiquidationCount }}</p>
          </div>
          <div class="pro_card">
            <p class="card_title">应清算项目数</p>
            <p class="card_txt">{{ basicInfo.shouldBeLiquidatedCount }}</p>
          </div>
          <div class="pro_card">
            <p class="card_title">商业&住宅预售均价</p>
            <p class="card_txt">{{ basicInfo.presalePrice }}<span>元</span></p>
          </div>
          <div class="pro_card">
            <p class="card_title">预售总面积</p>
            <p class="card_txt">
              {{ basicInfo.presaleTotalArea }}<span>m²</span>
            </p>
          </div>
          <div class="pro_card">
            <p class="card_title">预售总套数</p>
            <p class="card_txt">{{ basicInfo.presaleHouseCount }}</p>
          </div>
          <div class="pro_card">
            <p class="card_title">预售总许可证数</p>
            <p class="card_txt">{{ basicInfo.presalePermitLicenseCount }}</p>
          </div>
        </div>
      </div>
      <div class="item_box">
        <div class="czdb_box">
          <div class="item_title">
            各年度预售许可总面积趋势/历年项目数趋势/历年房屋总套数趋势
          </div>
          <img src="" alt="">
          <div v-if="lineData.year.length>0" class="tab_box">
            <div
              class="tab-item"
              :class="{ selected: activeName == 'jzzmj' }"
              @click="titleTab('jzzmj')"
            >
              建筑总面积
            </div>
            <div
              class="tab-item"
              :class="{ selected: activeName == 'xmsl' }"
              @click="titleTab('xmsl')"
            >
              项目数量
            </div>
            <div
              class="tab-item"
              :class="{ selected: activeName == 'zts' }"
              @click="titleTab('zts')"
            >
              总套数
            </div>
          </div>
          <trends v-if="lineData.year.length>0" :chartData="chartData"></trends>
          <div v-if="lineData.year.length==0" class="noData"></div>
        </div>
        <div class="sbbfb_box">
          <div class="item_title">{{ rateTxt }}</div>
          <div v-if="isCounty" class="tab_box" style="margin-left: 24px">
            <div
              class="tab-item"
              :class="{ selected: houseName == 'xszt' }"
              @click="houseTab('xszt')"
            >
              房屋销售状态
            </div>
            <div
              class="tab-item"
              :class="{ selected: houseName == 'qyfb' }"
              @click="houseTab('qyfb')"
            >
              项目区域分布
            </div>
          </div>
          <houseRatio
            v-if="houseName == 'xszt'"
            :houseRatioData="houseRatioData"
          ></houseRatio>
          <regionRatio
            v-if="houseName == 'qyfb'"
            :regionRatioData="regionRatioData"
          ></regionRatio>
        </div>
      </div>
      <div class="item_box">
        <div class="build_box">
          <div class="item_title">预售许可证同年项目列表</div>
          <el-form :inline="true">
            <el-form-item label="年度：">
              <el-select
                v-model="year"
                 @change="yearSelect"
                placeholder="点击选择年度"
              >
                <el-option
                  v-for="item in yearData"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="table_box">
            <el-table
              ref="table"
              width="910"
              :data="tableData"
              border
              stripe
              :header-cell-style="{ background: '#F6F6F6', color: '#333333' }"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="60"
              ></el-table-column>
              <el-table-column
                prop="projectName"
                label="项目名称"
              ></el-table-column>
              <el-table-column
                prop="company"
                label="企业名称"
                width="240"
              ></el-table-column>
              <el-table-column
                prop="address"
                label="项目地址"
                width="240"
              ></el-table-column>
              <el-table-column prop="area" label="总面积(m²)"></el-table-column>
              <el-table-column
                prop="floorCountTotal"
                label="总层数"
              ></el-table-column>
              <el-table-column
                prop="floorCountUp"
                label="地上总层数"
              ></el-table-column>
              <el-table-column
                prop="floorCountDown"
                label="地下总层数"
              ></el-table-column>
            </el-table>
            <div class="page-box">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                background
                layout="prev,pager,next,total,sizes,jumper"
                :page-sizes="[10, 50, 100]"
                :total="total"
                :current-page="pageNum"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStore, setStore, getLocalStore } from "@/util/store";
import { mapMutations } from "vuex";
import trends from "@/views/onlineSignature/overview/trends.vue";
import houseRatio from "@/views/onlineSignature/overview/houseRatio.vue";
import regionRatio from "@/views/onlineSignature/overview/regionRatio.vue";

export default {
  name: "index",
  components: {
    trends,
    houseRatio,
    regionRatio,
  },

  data() {
    return {
      basicInfo: {},
      costCompareShow: false,
      costRatioShow: false,
      activeName: "jzzmj",
      houseName: "xszt",
      isCounty: true,
      province: "",
      city: "",
      county: "",
      lineData: {
          year:[],
      },
      chartData: {},
      rateTxt: "项目销售比例/项目区域分布",
      pageNum: 1,
      pageSize: 10,
      year: "2021",
      tableData: [],
      total: 0,
      houseRatioData: {},
      regionRatioData: [],
      yearData:[],
    };
  },
  created() {
    if (getStore("selprovince") && getStore("selprovince").length > 0) {
      this.province = getStore("selprovince");
      this.city = getStore("selcity");
      this.county = getStore("selcounty");
    } else {
      this.province = getStore("province");
      this.city = getStore("city");
      this.county = getStore("county");
    }

    if (this.county.length != 0) {
      this.rateTxt = "房屋销售状态";
      this.isCounty = false;
    }
  },

  mounted() {
    //获取基本信息
    this.getBasicInfo();
    this.getRegionMetricsTrendsInfo();
    this.getSalesStatusInfo();
    this.getRegionalDistributionInfo();
    this.getListData();
    this.getYearsOfProject();
  },

  methods: {
    ...mapMutations(["addPath", "reducePath", "addMenuList", "reduceMenuList"]),
    back() {
      this.getReport(2, "back", "backBtn");
      this.reduceMenuList(); //删除历史菜单中最后一个元素（当前页的路由）
      this.reducePath(); //删除导航最后一个路由
      this.$router.push({ path: "/onlineSignature" });
    },
    //展现点击上报
    getReport(recordType, modelName, clickName) {
      this.axios
        .request({
          method: "post",
          url: "/dms/saveUserBehaviorRecord",

          data: {
            pageName: "industryAnalysis",
            userName: getLocalStore('areainfo').userName,
            recordType: recordType,
            modelName: modelName,
            clickName: clickName,
            userId: getLocalStore('areainfo').id,
            ipAddress: getLocalStore('areainfo').ipAddress,
            userAddress: getLocalStore('areainfo').userAddress,
            ubr: getStore("reportobj") || {},
          },
        })
        .then((res) => {
          setStore("reportobj", res.data.data);
        });
    },
    //基本信息
    getBasicInfo() {
      this.axios
        .request({
          method: "post",
          url: "/networkProject/region/getRegionMetricsInfo",
          data: {
            province: this.province,
            city: this.city,
            county: this.county,
          },
        })
        .then((res) => {
          this.basicInfo = res.data.data;
        });
    },
    //趋势
    getRegionMetricsTrendsInfo() {
      this.axios
        .request({
          method: "post",
          url: "/networkProject/region/getRegionMetricsTrendsInfo",
          data: {
            province: this.province,
            city: this.city,
            county: this.county,
          },
        })
        .then((res) => {
          this.lineData = res.data.data;
          this.chartData.type = "area";
          this.chartData.year = this.lineData.year;
          this.chartData.data = this.lineData.totalArea;
        });
    },
    //房屋状态
    getSalesStatusInfo() {
      this.axios
        .request({
          method: "post",
          url: "/networkProject/region/getSalesStatusInfo",
          data: {
            province: this.province,
            city: this.city,
            county: this.county,
          },
        })
        .then((res) => {
          this.houseRatioData = res.data.data;
        });
    },
    //区域项目数分布
    getRegionalDistributionInfo() {
      this.axios
        .request({
          method: "post",
          url: "/networkProject/region/getRegionalDistributionInfo",
          data: {
            province: this.province,
            city: this.city,
            county: this.county,
          },
        })
        .then((res) => {
            if(res.data.data){
                this.regionRatioData = res.data.data;
            }
          
        });
    },
    //获取年份列表
    getYearsOfProject() {
      this.axios
        .request({
          method: "get",
          url: "/networkProject/region/getYearsOfProject",
        })
        .then((res) => {
          this.yearData = res.data.data;
        });
    },
    //年份筛选
    yearSelect(){
        this.pageNum = 1;
        this.getListData()
    },
    //获取年度数据
    getListData() {
      this.axios
        .request({
          method: "post",
          url: "/networkProject/region/pageSameProjectOfYear",
          data: {
            city: this.city,
            county: this.county,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            year: this.year,
          },
        })
        .then((res) => {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        });
    },
    //切换条数
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.getListData();
    },
    //切换页码
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getListData();
    },
    //tab切换
    titleTab(val) {
      this.activeName = val;
      if (val == "jzzmj") {
        this.chartData = {};
        this.chartData.type = "area";
        this.chartData.data = this.lineData.totalArea;
        this.chartData.year = this.lineData.year;
      } else if (val == "xmsl") {
        this.chartData = {};
        this.chartData.type = "zts";
        this.chartData.data = this.lineData.totalProjectCount;
        this.chartData.year = this.lineData.year;
      } else {
        this.chartData = {};
        this.chartData.type = "zts";
        this.chartData.data = this.lineData.totalHouseCount;
        this.chartData.year = this.lineData.year;
      }
    },
    //房屋tab
    houseTab(val) {
      this.houseName = val;
    },
  },
};
</script>

<style scoped lang="less">
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 4px;
}
.contain {
  height: 100%;
  overflow-y: scroll;
  .main {
    width: 1200px;
    margin: 0 auto;
    position: relative;
  }
  .item_box {
    width: 100%;
    height: auto;
    margin-bottom: 24px;
    overflow: hidden;
    position: relative;
    .tb_left {
      width: 100%;
      height: 101px;
      overflow: hidden;
      float: left;
      .pro_card {
        width: 190px;
        height: 101px;
        background: #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        box-sizing: border-box;
        float: left;
        margin-right: 12px;
        margin-bottom: 10px;
        padding: 19px 20px;
        .card_title {
          font-size: 14px;
          font-weight: 500;
          color: #999999;
          margin-bottom: 14px;
        }
        .card_txt {
          font-size: 18px;
          font-weight: 600;
          color: #185bce;
          span {
            font-size: 12px;
          }
        }
      }
      .pro_card:last-child {
        margin-right: 0;
      }
    }
    .tb_map {
      float: right;
      width: 320px;
      height: 212px;
      position: relative;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
      .map_btn {
        width: 128px;
        height: 29px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 0px 0px 0px 10px;
        line-height: 29px;
        text-align: center;
        color: #ffffff;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .czdb_box {
      width: 778px;
      height: 368px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      float: left;
      margin-right: 16px;
      padding: 16px 24px;
      box-sizing: border-box;
    }
    .sbbfb_box {
      width: 405px;
      height: 368px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      float: left;
      box-sizing: border-box;
      .item_title {
        margin-left: 24px;
        margin-top: 16px;
      }
    }
    .card_box {
      width: 239px;
      height: 293px;
      float: left;
      .card {
        width: 239px;
        height: 135px;
        background: #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        margin-bottom: 24px;
        padding-top: 16px;
        box-sizing: border-box;
        p {
          text-align: center;
          margin-bottom: 16px;
          font-weight: 600;
          color: #333333;
          font-size: 14px;
        }
        .bfb {
          font-size: 18px;
          font-weight: 600;
          color: #185bce;
          span {
            font-size: 12px;
          }
        }
        .num {
          font-weight: 400;
          color: #999999;
          span {
            color: #185bce;
          }
        }
      }
    }
    .build_box {
      width: 1200px;
      height: auto;
      background: #ffffff;
      border-radius: 2px;
      padding: 16px 24px;
      box-sizing: border-box;
    }
    .construction_box {
      width: 1200px;
      height: 202px;
      background: #ffffff;
      border-radius: 2px;
      padding: 16px 24px;
      box-sizing: border-box;
      .list_box {
        width: 100%;
        overflow: hidden;
        .const_item {
          width: 255px;
          border-right: 1px solid #d8d8d8;
          float: left;
          margin-right: 30px;
          padding-left: 40px;
          box-sizing: border-box;
          div {
            margin-bottom: 8px;
          }
          p {
            font-size: 14px;
            font-weight: 600;
            color: #999999;
            margin-bottom: 8px;
            span {
              font-size: 18px;
              font-weight: 600;
              color: #185bce;
              margin-left: 3px;
            }
          }
        }
        .const_item:last-child {
          border: none;
        }
      }
    }
    .fxxl_box {
      width: 1200px;
      height: 342px;
      background: #ffffff;
      border-radius: 2px;
      padding: 16px 24px;
      box-sizing: border-box;
    }
    .item_title {
      border-left: 6px solid #185bce;
      height: 22px;
      padding-left: 8px;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 24px;
      margin-bottom: 16px;
    }
    .unit {
      font-size: 12px;
      font-weight: 400;
      color: #8c8c8c;
      line-height: 17px;
    }
  }
  .tab_box {
    width: 100%;
    height: 27px;
    box-sizing: border-box;
    .tab-item {
      float: left;
      width: 100px;
      height: 27px;
      line-height: 27px;
      background: rgba(50, 50, 51, 0.06);
      color: #646566;
      cursor: pointer;
      border-right: 1px solid #d8d8d8;
      text-align: center;
    }
    .tab-item:last-child {
      border-right: none;
    }

    .selected {
      background: #185bce;
      color: #fff;
    }
  }
}
.map_box {
  /deep/.el-dialog {
    height: 90%;
  }
  /deep/.el-dialog__body {
    height: 100%;
    padding: 0;
  }
  /deep/.el-dialog__header {
    padding: 0;
  }
}
.tag {
  color: #969799;
  margin: 16px 0 32px;
}
.back {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #185bce;
  cursor: pointer;
}
.map {
  position: absolute;
  top: 0px;
  right: 0px;
}
/deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
  outline: 0;
  padding: 0 12px;
}
.el-form{
    position: absolute;
    right: 15px;
    top: 5px;
}
</style>