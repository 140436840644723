<template>
  <div class="chart">
    <div id="regionchart"></div>
  </div>
</template>
<script>
export default {
  props: {
    regionRatioData: {},
  },
  data() {
    return {
      regionChart:{},
      nameArr:[],
      valArr:[],
      dataArr:[],
      count:0
      
    };
  },
  watch:{
    regionRatioData:{
      handler: function(){
          this.renderEcharts();
      },
      deep: true
    }
  },
  mounted(){
    this.regionRatioData.forEach((item,i) => {
      this.dataArr.push({
        value:item.count,
        name:item.region
      })
      this.count+=item.count;
      this.valArr.push(item.count)
      this.nameArr.push(item.region)
    })
    setTimeout(() => {
      this.renderEcharts();
    }, 500);
    
  },
  
  methods: {
    renderEcharts(){
      this.regionChart = this.$echarts.init(document.getElementById('regionchart'));
      let option;
      option = {
          title: {
              text:'项目总数',//主标题文本
              subtext: this.count,//副标题文本
              left:'130px',
              top:'40%',
              textAlign:'center',
              textStyle:{
                fontSize: 14,
                color:'#999999',
              },
              subtextStyle:{
                  fontFamily : "微软雅黑",
                  fontSize: 16,
                  color:'#555555',
                  fontWeight: '600',
              }
          },
          tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          legend: {
              orient: 'vertical',
              icon: "circle",
              right: 20,
              bottom:20,
              data: this.nameArr,
            
          },
          series: [
              {
                  name: '百分比',
                  type: 'pie',
                  radius: ['55%', '90%'],
                  avoidLabelOverlap: false,
                  center: ['135px', '50%'],
                  label: {
                    normal:{
                        show:true,
                        position:'inner', //标签的位置
                        textStyle : {
                          color:'#fff',
                          fontWeight : 300 ,
                          fontSize : 12    //文字的字体大小
                        },
                        formatter:'{c}'
                    }
                  },
               
                  labelLine: {
                      show: false
                  },
                  data: this.dataArr
              }
          ]
      };


      this.regionChart.setOption(option,true);
    },

    
  },
};
</script>
<style lang="less" scoped>
.chart{
  width: 381px;
  height: 400px;
  margin-top: 24px;
 
}
#regionchart{
  width: 381px;
  height: 230px;
}
</style>