<template>
  <div class="chart">
    <div id="chartbox"></div>
  </div>
</template>
<script>
export default {
  props: {
    chartData: {},
  },
  data() {
    return {
      myChart: {},
    };
  },
  watch: {
    chartData(){
      this.renderEcharts();
    },
  },
  mounted() {
    setTimeout(() => {
      this.renderEcharts();
    }, 500);
  },

  methods: {
    //加载echarts图表
    renderEcharts() {
      let myChart = this.$echarts.init(document.getElementById("chartbox"));
      let option;
      if (this.chartData.type == "area") {
        option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
          },
          xAxis: [
            {
              type: "category",
              data: this.chartData.year,
              axisPointer: {
                type: "shadow",
              },
            },
          ],
          yAxis: {
            type: "value",
            name: "平方米",
            axisLabel: {
              formatter: "{value}",
            },
          },
          series: [
            {
              data: this.chartData.data,
              name: "面积",
              type: "line",
            },
          ],
        };
      } else{
        option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
          },
          xAxis: [
            {
              type: "category",
              data: this.chartData.year,
              axisPointer: {
                type: "shadow",
              },
            },
          ],
          yAxis: {
            type: "value",
            name: "个",
            axisLabel: {
              formatter: "{value}",
            },
          },
          series: [
            {
              data: this.chartData.data,
              name: "数量",
              type: "line",
            },
          ],
        };
      }

      myChart.setOption(option);
    },
  },
};
</script>
<style lang="less" scoped>
.chart {
  width: 740px;
}
#chartbox {
  width: 740px;
  height: 320px;
}
</style>